import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';
import { Subject } from 'rxjs';
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';

@Component({
  selector: 'card-two',
  templateUrl: './card-two.component.html',
  styleUrls: ['./card-two.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
})
export class CardTwoComponent  {
  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('CardTwo')
  idPrefix = generateIdPrefix(ENV.idPrefix.cardTwo)
  @Input('props') props: CardTwoProps = new CardTwoProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()

  ngOnInit(): void {}

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

export class CardTwoProps {
  constructor(props:Partial<CardTwoProps>={}){
    let origProps = Object.entries(props).filter(([key,val]) => !key.startsWith('prop'));
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }
}
