import { silenceAllConsoleLogs, traverseClassAndRemoveUpdateAutomation } from "@core/utility/env-utils"
import { DevEnv } from "./environment.dev"
import { deepCopyExclude } from "@core/utility/object-utils"


export let environment = {
  production: true
}
class ProdEnv extends DevEnv  {


  constructor(){
    super()
    this.app.freeTrialEndsAfter = 1
    this.type = "PROD"
    this.frontendDomain0 = "https://eneobia.com"
    this.backendServerName0 = "api-eneobia.azurewebsites.net"
    this.firebase.storageImageURL ="https://firebasestorage.googleapis.com/v0/b/eneobia.appspot.com/o/"

    silenceAllConsoleLogs()
    traverseClassAndRemoveUpdateAutomation(this)
    this.mobile.android.googlePlayStore.storeUrl ="https://play.google.com/store/apps/details?id=com.windmillcode.eneobia"
    this.firebase.config ={
      ...this.firebase.config,
      apiKey:"AIzaSyAkdqk6KH1pv4xWE3zBMcjHi-6Y8lSslLY",
      authDomain: "eneobia.com",
      projectId: "eneobia",
      appId:"1:1087581737335:web:4a19d5c3a67ab30bf89172",
      measurementId:"G-Z02VKXGWHK"
    }
    this.peerjs = {
      ...deepCopyExclude(["port"],this.peerjs),
      host:this.backendServerName0
    }
    this.oneSignal.appId = "27a82ea5-5acb-421d-a1be-0e1b51fc1856"
    this.oneSignal.safariWebId ="web.onesignal.auto.32551e0e-3454-416e-9258-23f2fe99c3c8"

  }
}


export let ENV =   new ProdEnv()
